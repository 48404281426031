import { DateRangeFilter, SingleSelectSimpleFilter } from "@dash/filters";
import { Page, PageHeader } from "@dash/page";
import { Pagination } from "@dash/search";
import { QuerySkeleton } from "@dash/skeleton";
import { useTheme } from "@emotion/react";

import { notificationPermissionMap } from "@megaron/notifications-contracts";
import { useClientManager } from "@megaron/react-clients";

import { AllNotificationsTable } from "./AllNotificationsTable";
import { useNotificationsFilters } from "./useNotificationsFilters";

export const NotificationsPage = () => {
  const theme = useTheme();
  const pageSize = 20;
  const { filters, setFilter, setFilters } = useNotificationsFilters();

  const notificationsLogsQuery = useClientManager("notifications")
    .getNotificationLogs()
    .useQuery({
      offset: filters.page ?? 0,
      limit: pageSize,
      startDate: filters.startDate ? new Date(filters.startDate) : undefined,
      endDate: filters.endDate ? new Date(filters.endDate) : undefined,
      app: filters.app ? filters.app : undefined,
    });

  const uniqueApps = Array.from(new Set(Object.values(notificationPermissionMap).map((p) => p.app))).map((app) => ({
    label: app,
    value: app,
  }));

  return (
    <Page css={{ marginTop: "1rem" }}>
      <PageHeader>Powiadomienia</PageHeader>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          marginBottom: "1rem",
          "@media(min-width: 768px)": {
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          },
        }}
      >
        <DateRangeFilter
          variant="secondary"
          startDate={filters.startDate ? new Date(filters.startDate) : undefined}
          endDate={filters.endDate ? new Date(filters.endDate) : undefined}
          onChange={(range) => {
            setFilters({
              ...filters,
              startDate: range.startDate,
              endDate: range.endDate,
              page: 0,
            });
          }}
        />

        <SingleSelectSimpleFilter
          css={{
            background: "white",
            border: "none",
            color: theme.colors.primary,
            height: "41px",
            "& svg": {
              color: theme.colors.primary,
            },
          }}
          label={"Aplikacja"}
          value={filters.app}
          options={uniqueApps}
          onChange={setFilter("app")}
        />
        <Pagination
          css={{ maxHeight: "41px", alignSelf: "flex-end" }}
          page={filters.page || 0}
          onPageChange={setFilter("page")}
          pageSize={pageSize}
          itemCount={notificationsLogsQuery.data?.count}
        />
      </div>

      <QuerySkeleton query={notificationsLogsQuery}>
        {(result) => (
          <AllNotificationsTable notifications={result.items} isLoading={notificationsLogsQuery.isLoading} />
        )}
      </QuerySkeleton>
    </Page>
  );
};
