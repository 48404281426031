import { useQsFilters } from "@dash/filters";

import { Serializers, SerializerValue, SortFilter } from "@megaron/serializers";

export const filtersSerializer = Serializers.object({
  page: Serializers.integer.optional(),
  startDate: Serializers.datetime.optional(),
  endDate: Serializers.datetime.optional(),
  app: Serializers.string.optional(),
});

export type Filters = SerializerValue<typeof filtersSerializer>;

export const useNotificationsFilters = () => {
  const defaultFilters: Filters = {
    page: 0,
    startDate: undefined,
    endDate: undefined,
    app: undefined,
  };

  const { filters, setFilters } = useQsFilters(filtersSerializer, defaultFilters);

  const setFilter =
    <Tkey extends keyof Filters>(key: Tkey) =>
    (value: Filters[Tkey]) => {
      setFilters({ ...filters, [key]: value });
    };

  return { filters, setFilter, defaultFilters, setFilters };
};
